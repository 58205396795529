import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import {BuddyTabContainer } from '../PeopleHomeV2/styles'
import HoverButton from '../common/HoverButton'
import { BackIcon, CartIcon } from '../../utils/icons'
import { ProgramDetailContainer, Title } from './style'
import { toast } from 'react-toastify'
import { fetchApi } from '../../utils/methods'
import { GetHealthProgramDetails } from '../../redux/constants/apiConstants'
import { useParams } from 'react-router-dom';
import LazyImage from '../common/LazyImage/LazyImage';
import { ImageUrl } from '../../utils/constants';
import { Border } from '../EventDetails/styles';
import CoachProfile from '../EventDetails/CoachProfile';
import SkeletonLoder from '../common/skeletonLoder';
import parse from 'react-html-parser';

const ProgramDetail = ({history}) => {
  const { id } = useParams();
  
  const [ProgramDetails, setProgramDetails] = React.useState([]);
  const [ProgramDetailsLoading, setProgramDetailsLoading] = React.useState(false);
  const [ActiveMonth, setActiveMonth] = React.useState(null);

  useEffect(() => {
    !ProgramDetails.length && GetPrograDetail(id);
  }, []);

  const GetPrograDetail = async (id) => {
    window.console.log('id');
    setProgramDetailsLoading(true);
    const apiUrl = GetHealthProgramDetails + "/" + id;
    
    try {
      const res = await fetchApi(apiUrl, "GET");
      if (res.status === "success") {
        setProgramDetailsLoading(false);
        setProgramDetails(res?.data);
      } else {
        toast.error(res.error);
        setProgramDetailsLoading(false);
      }
    } catch (error) {
      window.console.log(error);
    }
  };

  const headerView = ()=>{
    window.console.log('headerView')
    return(
      <>
        <BuddyTabContainer style={{margin:"20px auto"}}>
          <div>
            <HoverButton title={"Back"} svgPath={BackIcon()} onClick={()=>history.goBack()}/>
          </div>
          <Title>
            <span className='title'>{ProgramDetails?.category_names?.[0] ? ProgramDetails?.category_names?.[0]  + " Program" : "Weight Loss Program"}</span>
          </Title>
        </BuddyTabContainer>
      </>

    )
  }

  const handleActiveMonth = (index)=>{

    if(ActiveMonth === index){
      setActiveMonth(null)
    }else{
      setActiveMonth(index)
    }
  }


  const programDetailsView = ()=>(

    <ProgramDetailContainer>

      <div className='main-container'>
        <div className='left-container'>
          <div className='title'><span>{ProgramDetails?.title}</span></div>
          <LazyImage src={ImageUrl + "/" + ProgramDetails?.image} style={{borderRadius:"6px",width:"100%",height:"472px",background: "lightgray 50% / cover no-repeat"}} alt="program image" />
          <Border style={{margin:"20px 0px"}}/>
          <div className='sub-title'><span>About The Program</span></div>
          <div className='about'>
            <span>{parse(ProgramDetails?.about)}</span>
          </div>
          {/* <div className='about'><span dangerouslySetInnerHTML={{ __html: ProgramDetails?.about }}/></div> */}
        </div>
        <div className='right-container'>
          <div className='purchase-btn'>{CartIcon()}<span style={{marginLeft:"12px"}}>Purchase Program</span></div>
          <Border style={{margin:"28px 0px"}}/>
          {ProgramDetails?.months_weeks_json?.map((item, index)=>(
            <div key={index}>
              {item?.month ? (<>
                
                <div className='month-container' onClick={()=>handleActiveMonth(index)}>
                  <div className='month'><span>Month {index+1} : {item?.month}</span></div>
                  <div><LazyImage src={ActiveMonth === index ? "/public/images/dropdown_arrow.svg": "/public/images/teamDownArrow.svg"}/> </div>
                </div>
                
                {ActiveMonth === index && item?.weeks?.map((week, weekIndex)=>(
                  <div key={weekIndex} style={{marginTop:weekIndex === 0 && "20px"}}>
                    {week ? (<><div className='week'><span>Week {weekIndex+1} : {week}</span></div>
                      {item?.weeks?.length != weekIndex+1 && <Border style={{margin:"8px 0px"}}/>}
                    </>): null} 
                  </div>
                ))}</>):null}
              <Border style={{margin:"20px 0px"}}/>
            </div>
          ))}
        </div>
      </div>

    </ProgramDetailContainer>

  )
  return (
    <div style={{width:"1248px",margin:"20px auto"}}>
      {ProgramDetailsLoading ? (<SkeletonLoder width={"1248px"} height={"400px"}/>): 
        <>
          {headerView()}
          {programDetailsView()}
          {ProgramDetails?.coach_details && (<div style={{ marginTop: "40px", marginBottom: "80px", display:"flex",justifyContent:"center" }}>
            <CoachProfile coachDetail={ProgramDetails?.coach_details} history={history}/>
          </div>)}

        </>
    
      }
      

    </div>
  )
}

ProgramDetail.propTypes = {
  
  history: PropTypes.object.isRequired,
}

export default ProgramDetail;